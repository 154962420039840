.help {
  position: relative;
  display: inline-block;

  &.left {
    .help__content {
      right: 0;
    }
  }

  &.right {
    .help__content {
      left: 0;
    }
  }

  &:hover {
    .help__content {
      display: block;
    }
  }

  &__icon {
    background-image: url('../../../img/icons/help.svg');
    background-repeat: no-repeat;
    background-position: center;
    width: 3rem;
    height: 2rem;
    background-size: contain;
    cursor: pointer;
  }

  &__content {
    display: none;
    z-index: 5;
    background-color: #ffffff;
    padding: 1rem 2rem;
    font-size: 1.3rem;
    border-radius: 0.8rem;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);
    width: 100%;
    box-sizing: border-box;

    position: fixed;

    @media #{$landscapePhone} {
      min-width: 25rem;
      max-width: 30rem;
      position: absolute;
      top: 2rem;
    }
  }
}
