.dashboard-layout {
  max-width: 100vw;

  @media #{$tablet} {
    display: flex;
    min-height: 100vh;
  }

  &__sidebar {
  }

  &__content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow: auto;
    position: relative;
    min-height: 100vh;

    @media #{$tablet} {
      display: block;
    }

    .notification-bar {
      order: 2;

      @media #{$tablet} {
        order: unset;
      }
    }

    .dashboard-header {
      order: 1;
      height: 6.6rem;
      box-sizing: border-box;

      @media #{$tablet} {
        height: auto;
        order: unset;
      }

      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1rem 2rem;

      @media #{$tablet} {
        padding: 1rem 2rem 3rem;
      }

      @media #{$tablet} {
        padding: 1rem 2rem;
      }

      .site-location {
        font-size: 1.3rem;
        color: #839eac;
        visibility: hidden;

        @media #{$tablet} {
          visibility: visible;
        }

        a {
          margin-right: 0.5rem;

          &:not(:first-child) {
            margin-left: 0.5rem;
          }
        }
      }

      .user-profile {
        position: relative;
        z-index: 5;

        button.toggle-dropdown {
          outline: 0;
          border: 0;
          padding: 1.3rem 1rem;
          cursor: pointer;
          background: transparent;
          border-radius: $inputRadius;
          display: flex;
          align-items: center;

          @media #{$landscapePhone} {
            padding: 1.3rem 1.5rem;
          }

          @media #{$tablet} {
            padding: 1.3rem 2rem;
          }

          span {
            font-weight: 600;
            margin-right: 1rem;
            white-space: nowrap;
            font-size: 1.4rem;

            @media #{$landscapePhone} {
              font-size: 1.5rem;
              margin-right: 1.5rem;
            }
          }

          .arrow {
            transform: rotate(0);
            transition: transform 150ms ease;
          }

          &:hover,
          &:focus {
            background-color: #e8ecf2;
          }

          &:focus {
            box-shadow: inset 0 0 0 0.3rem #d2d9e4;
          }
        }

        &--open {
          button.toggle-dropdown {
            background-color: #e8ecf2;

            .arrow {
              transform: rotate(180deg);
            }
          }
        }

        &__dropdown {
          right: 0;
          top: 100%;
          position: absolute;
          list-style: none;
          margin: 0;
          padding: 2.7rem 3.5rem;
          min-width: 19rem;
          box-sizing: border-box;
          background: white;
          box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);
          border-radius: $inputRadius;
          transition: all 200ms ease;
          z-index: 50;

          $visibleTranslateValue: 1rem;
          $hiddenTranslateValue: 3rem;
          transform: translateY($visibleTranslateValue);

          &-enter {
            opacity: 0;
            transform: translateY($hiddenTranslateValue);
          }
          &-enter-active {
            opacity: 1;
            transform: translateY($visibleTranslateValue);
          }
          &-exit {
            opacity: 1;
            transform: translateY($visibleTranslateValue);
          }
          &-exit-active {
            opacity: 0;
            transform: translateY($hiddenTranslateValue);
          }

          li {
            &:not(:last-child) {
              margin-bottom: 0.6rem;
            }

            button,
            a {
              border: 0;
              padding: 0;
              margin: 0;
              background: transparent;
              display: block;
              color: #0a243b;
              font-size: 1.5rem;
              font-weight: 400;
              text-decoration: none;
              width: 100%;
              text-align: left;
              cursor: pointer;
              outline: 0;

              &:hover,
              &:focus {
                font-weight: 600;
              }
            }
          }
        }
      }
    }

    .dashboard-main {
      margin-top: 3rem;
      @media #{$tablet} {
        margin-top: 0rem;
      }

      order: 3;
      @media #{$tablet} {
        order: unset;
      }

      padding-bottom: 4rem;
    }
  }
}
