.dashboard-panel-wrapper {
  position: relative;

  .full-div-loader {
    border-radius: 0.7rem;
  }
}

.dashboard-panel {
  border-radius: 0.7rem;
  background: #ffffff;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);
  padding: 3rem 3rem;
  position: relative;

  & > .loader-shade {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: white;
    z-index: 1;

    & > .loader {
      width: 5rem;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .help {
    position: absolute;
    right: 1rem;
    top: 1.2rem;
  }

  @media #{$landscapePhone} {
    padding: 3rem 4rem;
  }

  .panel-row {
    display: flex;
    align-items: center;

    .full-div-loader {
      margin-bottom: -2rem;
    }

    &:not(:first-child) {
      padding-top: 2rem;
    }
    &:not(:last-child) {
      padding-bottom: 2rem;
      border-bottom: 0.1rem solid #c4c4c4;
    }
  }
}
