.simple-content {
  margin-top: 3rem;

  &__icon {
    width: 6rem;
    display: block;
    margin: 0 auto 4rem auto;
  }

  &__title {
    text-align: center;
    font-weight: 700;
    font-size: 3rem;
    line-height: 3.5rem;
    color: #0b415a;
    margin: 0 auto 3rem auto;
    max-width: 50rem;
  }

  &__description {
    text-align: center;
    max-width: 60rem;
    margin-bottom: 6rem;
  }

  &__content {
  }
}

#simple-central-page {
  main.simple-layout__main {
    display: flex;
    align-items: center;
    text-align: center;

    h1 {
      margin-top: 5rem;
      margin-bottom: 5rem;
      max-width: 50rem;
    }
  }

  #simple-central-loader {
    width: 15rem;
    margin: 3rem auto;
  }
}
