button.link {
  display: inline-block;
  padding: 0;
  margin: 0;
  border: 0;
  text-decoration: underline;
  color: #757576;
  outline-color: #0b415a;
  cursor: pointer;
  background: transparent;
  font-size: inherit;

  &:hover {
    color: #a5a5a5;
  }
}

.btn {
  color: #fff;
  text-transform: uppercase;
  padding: 2.2rem 5rem 2.3rem;
  border: 0;
  text-align: center;
  cursor: pointer;
  outline: 0;
  display: inline-block;
  border-radius: $inputRadius;
  position: relative;
  transition: background-color 150ms ease;
  white-space: nowrap;
  white-space: pre;
  text-decoration: none;
  justify-content: center;

  &.medium {
    padding: 1.4rem 4rem 1.4rem;
    &.arrow .arrow {
      top: 1.8rem;
    }
  }

  &.small {
    padding: 1rem 2.5rem;
  }

  &.extra-small {
    padding: 0.2rem 1rem;

    .loader {
      width: 2rem;
      height: 2rem;
    }

    .label {
      font-size: 1.1rem;
    }
  }

  /** Label element **/
  .label {
    font-weight: 600;
    transition: opacity 200ms linear;
    font-size: 1.3rem;
  }

  /** Loader element **/
  .loader {
    width: 3rem;
    height: 3rem;
    position: absolute;
    left: 50%;
    top: 100%;
    opacity: 0;
    display: none;
    transition: transform 0ms linear, top 0ms linear, opacity 300ms ease-out;
  }

  /** Button ARROW mode **/
  &.arrow {
    padding-right: 6rem;

    /** Arrow element **/
    .arrow {
      font-size: 1.3rem;
      position: absolute;
      top: 2.5rem;
      right: 2.4rem;
      transition: all 150ms ease;
    }
  }

  /** Button ICON mode **/
  &.icon {
    display: flex;
    align-items: center;
    padding-left: 4rem;

    &.small {
      padding-left: 3rem;
    }

    &.medium {
      padding-left: 3rem;
    }

    .icon {
      margin-top: -0.2rem;
      display: block;
      margin-right: 1rem;
      font-size: 1.4rem;
    }

    img.icon {
      width: 1.7rem;
      max-height: 1.7rem;
      display: block;
      margin-right: 1rem;
    }
  }

  /** Button LOADING mode **/
  &.loading {
    .label,
    .arrow,
    .icon {
      opacity: 0;
    }

    .loader {
      top: 50%;
      transform: translate(-50%, -50%);
      opacity: 1;
      display: block;
    }
  }

  &.blue {
    background-color: #3d96c3;

    &:focus {
      box-shadow: inset 0 0 0 0.3rem #1375a7;
    }

    &:disabled {
      background-color: #72c8f3;
    }

    &:hover:enabled {
      background-color: #5ab4e2;
    }
  }

  &.red {
    &.outline {
      background: transparent;
      box-shadow: inset 0 0 0 0.1rem #cb4147;
      color: #cb4147;
    }
    background-color: #cb4147;

    &:focus {
      box-shadow: inset 0 0 0 0.3rem #912228;
    }

    &:disabled {
      background-color: #dd636a;
    }

    &:hover:enabled {
      background-color: #db585e;
      color: white;
    }
  }

  &.green {
    background-color: #2ec385;

    &:focus {
      box-shadow: inset 0 0 0 0.3rem #1d9e68;
    }

    &:disabled {
      background-color: #4cd39b;
    }

    &:hover:enabled {
      background-color: #47d399;
    }
  }

  &.locked:disabled {
    background-color: #c0c0c0;
  }

  /** Button DISABLED mode **/
  &:disabled {
    cursor: inherit;

    .arrow {
      opacity: 0;
    }
  }

  /** Button HOVER mode **/
  &:hover:enabled {
    .arrow {
      right: 3rem;
    }
  }
}
