$fieldFocusBorderColor: #3bbcfb;
$fieldFocusErrorBorderColor: $errorRed;

.StripeElement {
  display: block;
  border: 0.1rem solid $inputBorderColor;
  border-radius: $inputRadius;
  padding: 1.7rem 1.5rem;
  color: #000000;
  font-size: 1.4rem;
  width: 100%;
  box-sizing: border-box;
  transition: all 150ms ease;
}

.text-input-wrapper {
  flex-grow: 1;
  position: relative;
  margin-bottom: 1rem;

  @media #{$tablet} {
    margin-bottom: 0;
  }

  .loader {
    position: absolute;
    width: 3rem;
    height: 3rem;
    right: 1rem;
    top: 3.7rem;
  }

  label {
    display: block;
    color: #808080;
    font-size: 1.3rem;
    margin-bottom: 0.5rem;

    .required {
      color: $errorRed;
    }
  }

  input {
    display: block;
    border: 0.1rem solid $inputBorderColor;
    border-radius: $inputRadius;
    padding: 1.5rem 1.5rem;
    color: #000000;
    font-size: 1.4rem;
    width: 100%;
    box-sizing: border-box;
    transition: all 150ms ease;

    &.error {
      border: 0.1rem solid $errorRed;
    }

    /* Datepicker input */
    &.datePicker {
      background-image: url('../../img/datepicker/calendar.svg');
      background-position: right 1.7rem top 1.3rem;
      padding-right: 5.5rem;
      background-repeat: no-repeat;
    }
  }

  /* Placeholders */
  ::placeholder {
    opacity: 0.8;
  }
  :-ms-input-placeholder {
    opacity: 0.8;
  }
  ::-ms-input-placeholder {
    opacity: 0.8;
  }

  input:focus {
    outline: 0;
    border: 0.1rem solid $fieldFocusBorderColor;
    box-shadow: 0 0 0 0.1rem $fieldFocusBorderColor inset;

    &.error {
      border: 0.1rem solid $fieldFocusErrorBorderColor;
      box-shadow: 0 0 0 0.1rem $fieldFocusErrorBorderColor inset;
    }
  }

  .error-message {
    margin: 0.5rem 0;
    display: block;
    color: $errorRed;
    font-size: 1.2rem;
  }
}
