$inputRadius: 0.4rem;
$inputBorderColor: #adadad;
$bodyBackgroundColor: #f6f7f9;
$flatBoxShaddow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);
$brightBlue: #3bbcfb;
$normalBlue: #3d96c3;
$errorRed: #ff0000;

// Breaking points
$landscapePhone: 'only screen and (min-width : 576px)';
$tablet: 'only screen and (min-width : 768px)';
$desktop: 'only screen and (min-width : 992px)';
$largeDesktop: 'only screen and (min-width : 1200px)';

html {
  font-size: 62.5%;
}

body {
  margin: 0;
  padding: 0;
  font-size: 1.6rem;
  background-color: $bodyBackgroundColor;

  &.hide-hubspot {
    #hubspot-messages-iframe-container {
      transform: translatex(100%) !important;
    }
  }
}

html,
input,
button {
  font-family: 'Prompt', sans-serif;
}

.container {
  max-width: 140rem;
  margin: 0 auto;
  padding: 0 2rem;

  @media #{$landscapePhone} {
    padding: 0 3rem;
  }
}

a,
button {
  outline-color: #0b415a;
}

h1 {
  font-weight: 700;
  font-size: 3rem;
  line-height: 3.5rem;
  color: #0b415a;
  margin: 0 auto 3rem auto;
}

p {
  font-size: 1.5rem;
  color: #757576;
  margin: 0 auto 4rem auto;

  a {
    color: #757576;

    &:hover {
      color: #a5a5a5;
    }
  }
}

@import './icons';
@import '../components/components';
@import '../pages/pages';
@import '../forms/forms';
