.dashboard-section {
  margin-bottom: 4rem;

  h2 {
    color: #000000;
    font-size: 2.5rem;
    font-weight: 400;
    margin-bottom: 2rem;
  }

  &:first-child h2 {
    margin-top: 0;
  }
}
