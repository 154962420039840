@import './components/plan-preview';

.customize-plan {
  @media #{$tablet} {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  .customize-section-title {
    display: flex;
    & > h2 {
      margin: 0;
      padding: 0;
      font-weight: 400;
      text-transform: uppercase;
      font-size: 1.5rem;
      letter-spacing: 0.2rem;
      margin-bottom: 2rem;
    }
  }

  &__profiles {
    flex-grow: 1;

    @media #{$tablet} {
      margin-right: 2rem;
    }
  }

  &__checkout {
    margin-top: 3rem;

    @media #{$tablet} {
      margin-top: 0;
    }

    .notes {
      text-align: center;
      margin-top: 2rem;
      font-size: 1.3rem;

      a {
        color: #a7a7a7;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    .btn {
      width: 100%;
    }
  }
}
