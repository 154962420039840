.card-form {
  width: 100%;

  button {
    width: 100%;

    @media #{$tablet} {
      width: auto;
    }
  }

  @media #{$tablet} {
    min-width: 60rem;
  }
}
