.profile-panel {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;

  &__info {
    flex-grow: 1;
    margin-right: 2rem;

    h3 {
      font-size: 1.7rem;
      font-weight: 400;
      margin: 0;
    }

    p {
      font-size: 1.2rem;
      margin: 0;
    }
  }

  &__btns {
  }
}
