.profile-form {
  .submit-row {
    display: flex;
    justify-content: flex-end;
  }

  &--edit {
    .submit-row {
      justify-content: space-between;
    }
  }
}
