$borderColor: #c2c2c2;

.pagination {
  margin-top: 2.5rem;
  align-items: center;

  button.arrow {
    cursor: pointer;
    background: transparent;
    border: 0;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    width: 2rem;
    height: 1.5rem;
    outline: 0;
    transition: opacity 200ms ease;

    &:disabled {
      visibility: hidden;
    }

    &:hover {
      opacity: 0.5;
    }

    &--left {
      margin-right: 1rem;
      background-image: url('../../../img/icons/left-page-arrow.svg');
    }
    &--right {
      margin-left: 1rem;
      background-image: url('../../../img/icons/right-page-arrow.svg');
    }
  }

  button.number {
    border: 0;
    background: transparent;
    color: #6b6b6b;
    outline: 0;
    cursor: pointer;
    padding: 0.7rem 1.3rem;
    border-radius: $inputRadius;
    font-size: 1.4rem;
    transition: all 200ms ease;

    &:not(:last-child) {
      margin-right: 0.5rem;
    }

    &.active {
      background-color: #e9eaeb;
    }

    &:hover {
      background-color: #e9eaeb;
    }
  }
}

.table-wrapper {
  position: relative;
}

.table {
  box-shadow: $flatBoxShaddow;
  overflow-x: auto;
  background-color: #ffffff;
  border-radius: 0.7rem;

  /* width */
  &::-webkit-scrollbar {
    height: 0.7rem;
    width: 0.7rem;
  }
  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #a7a7a7;
  }
  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #888;
  }

  .empty {
    text-align: center;
    padding: 12rem 0;

    span {
      display: block;
      font-weight: 600;
      color: #9e9e9e;
    }
  }

  .loading {
    text-align: center;
    padding: 3rem 0;

    .loader {
      width: 5rem;
      margin: 0 auto;
    }

    span {
      display: block;
      font-weight: 600;
      color: #9e9e9e;
    }
  }

  // For link rows
  a {
    text-decoration: none;
    display: block;
    min-width: fit-content;

    &:not(:last-child) {
      border-bottom: 0.1rem solid $borderColor;
    }
  }

  .row {
    &--header {
      background-color: #fafafa;

      .row__content .cell {
        font-size: 1.5rem;
        padding: 2rem 1rem;
        justify-content: center;
        text-align: center;

        @media #{$tablet} {
          padding: 2.5rem 1.5rem;
        }
      }
    }

    &--normal {
      .row__content .cell {
        justify-content: start;
      }
    }

    &--url {
      &:hover {
        background-color: #f2f2f2;
      }
      &:focus {
        background-color: #ebebeb;
      }
    }

    &--extra {
      display: block;

      & > .row__content {
        cursor: pointer;

        &:hover {
          background-color: #f2f2f2;
        }
        &:focus {
          background-color: #ebebeb;
        }
      }

      .row__extra {
        padding: 2rem 3rem;
        display: none;
        border-top: 0.1rem solid #e2e2e2;

        &.open {
          display: block;
        }

        .table {
          width: 100%;

          .row__content .cell {
            padding: 1rem 1rem;

            @media #{$tablet} {
              padding: 1.2rem 1rem;
            }
          }
        }
      }
    }

    &:not(:last-child) {
      border-bottom: 0.1rem solid $borderColor;
    }

    &__content {
      display: flex;
      width: 100%;

      .cell {
        padding: 1.5rem;
        flex-grow: 1;
        flex-shrink: 0;
        box-sizing: border-box;
        overflow-x: hidden;
        display: flex;
        align-items: center;

        & > span {
          font-size: 1.4rem;
          color: #545454;
        }

        @media #{$tablet} {
          padding: 2rem;
        }

        &:not(:last-child) {
          border-right: 0.1rem solid $borderColor;
        }
      }
    }
  }
}
