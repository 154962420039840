.brief-card-info {
  display: flex;
  align-items: center;

  .card-brand {
    margin-right: 1rem;
  }

  .card-information {
    & > span {
      font-size: 1.3rem;
      display: block;
    }
  }
}
