button.add-btn {
  display: block;
  border: 0;
  outline: 0;
  width: 100%;
  background-color: #e9edf3;
  text-align: center;
  padding: 2.5rem;
  border-radius: $inputRadius;
  cursor: pointer;
  transition: backgroundColor 100ms ease;

  &:hover {
    background-color: #e1e6ee;
  }

  &:focus {
    box-shadow: inset 0 0 0 0.3rem #d0d7e2;
  }

  span {
    color: #79849a;
    text-transform: uppercase;
    font-size: 1.4rem;
    font-weight: 600;
  }
}
