.inforow {
  border-radius: $inputRadius;
  padding: 0.8rem 2rem 0.8rem 4rem;
  background-repeat: no-repeat;
  background-position: top 50% left 1.5rem;
  background-size: 1.6rem 1.6rem;
  margin-bottom: 1.3rem;

  span {
    font-size: 1.3rem;
  }

  &--success {
    background-color: #2ec385;
    color: white;
    background-image: url('../../../img/inforow/success.svg');
  }

  &--error {
    background-color: #e95858;
    color: white;
    background-image: url('../../../img/inforow/error.svg');
  }
}
