.checkout-page {
  h1 {
    font-size: 3rem;
    font-weight: 500;
    display: block;
    margin: 6rem 0 1rem 0;
    padding: 0;
  }

  .billing-cycle {
    color: #8a8a8a;
    margin-bottom: 2rem;
  }

  .card-options {
    margin-top: 2rem;
    margin-bottom: 3rem;
  }

  .payment-options {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    text-align: right;
    width: 100%;

    button {
      color: #8a8a8a;
      font-size: 1.4rem;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .notes {
    text-align: center;
    margin-top: 2rem;

    a {
      text-decoration: none;
      font-size: 1.4rem;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

@import './components/checkout-panel';
@import './components/coupon-panel';
