.plan-preview {
  padding: 3rem;
  box-sizing: border-box;
  margin-bottom: 2rem;
  position: relative;

  @media #{$tablet} {
    width: 33rem;
  }

  .loader-shade {
    position: absolute;
    left: 0;
    top: 0;
    background-color: rgba(255, 255, 255, 0.8);
    width: 100%;
    height: 100%;
    z-index: 1;

    .loader {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 4rem;
    }
  }

  &__total-price {
    font-weight: 500;
    font-size: 3.2rem;
    text-align: center;
  }

  &__product {
    text-align: center;
    font-size: 1.4rem;
  }

  &__billing-cycle {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
    font-size: 1.3rem;
    color: #a5a5a5;

    span.active {
      color: #3f3f3f;
      font-weight: 500;
    }
    .monthly {
      margin-right: 0.7rem;
    }
    .annually {
      margin-left: 0.7rem;
    }
  }

  &__features {
    padding-top: 2rem;
    border-top: 0.1rem solid #c4c4c4;

    ul {
      list-style: none;
      margin: 0;
      padding: 0 2rem;

      li {
        font-size: 1.3rem;
        background-image: url('../../../img/icons/list-checkmark.svg');
        background-repeat: no-repeat;
        padding-left: 2.5rem;
        background-position-y: 0.3rem;

        &:not(:last-child) {
          margin-bottom: 1rem;
        }
      }
    }
  }
}
