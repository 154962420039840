.panel {
  border-radius: 0.7rem;
  background: #ffffff;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);

  &--form {
    padding: 5rem 2rem 5rem;

    @media #{$landscapePhone} {
      padding: 5rem 6rem 6rem;
    }

    @media #{$tablet} {
      padding: 6rem 10rem 8rem;
    }
  }

  &--profile {
    padding: 3rem;
  }

  p {
    color: #8a8a8a;
  }

  &__icon {
    display: block;
    margin: 1rem auto 4rem auto;
  }

  &__title {
    text-align: center;
    font-weight: 700;
    font-size: 3rem;
    line-height: 3.5rem;
    color: #0b415a;
    margin: 0 auto 3rem auto;
    max-width: 50rem;
  }
  &__description {
    text-align: center;
    max-width: 60rem;
  }
  &__buttons {
    text-align: center;
  }
}
