.resource-panel {
  margin-bottom: 2rem;

  @media #{$tablet} {
    display: flex;
    align-items: center;
  }

  &__content {
    display: flex;
    align-items: center;
    flex-grow: 1;
    margin-bottom: 2rem;

    @media #{$tablet} {
      margin-bottom: 0;
    }

    &__info {
      margin-left: 2rem;

      @media #{$tablet} {
        margin-right: 2rem;
      }

      h3 {
        font-size: 1.5rem;
        font-weight: 400;
        margin: 0;
      }

      p {
        font-size: 1.2rem;
        margin: 0;
      }
    }
  }

  &__controls {
    button {
      width: 100%;

      @media #{$tablet} {
        width: auto;
      }
    }
  }
}
