.notification-bar {
  padding: 1.5rem 2rem;
  font-size: 1.4rem;
  position: relative;
  z-index: 1;

  @media #{$desktop} {
    padding: 0.8rem 2rem;
    align-items: center;
    display: flex;
    justify-content: space-between;
  }

  &--error {
    background-color: #e95858;

    .message {
      color: #ffffff;
    }

    button {
      background-color: #ffebeb;

      &:disabled:hover {
        background-color: #ffebeb;
      }
      &:hover {
        background-color: #fffafa;
      }
    }
  }

  &--warning {
    background-color: #fcd486;

    .message,
    .icon::before {
      color: #6c4a08;
    }

    button {
      background-color: #ffefd0;
      min-width: 26rem;

      &:disabled:hover {
        background-color: #ffefd0;
      }
      &:hover {
        background-color: #fff9ec;
      }
    }
  }

  .content {
    display: flex;

    .icon {
      font-size: 1.7rem;
      margin-right: 1rem;
    }

    .message {
      flex-grow: 1;
    }
  }

  .actions {
    margin-left: 2.7rem;
    margin-top: 1rem;

    @media #{$desktop} {
      display: flex;
      align-items: center;
      margin-top: 0;
    }

    a {
      color: #000000;
      white-space: nowrap;
      display: block;
      margin-bottom: 1rem;

      @media #{$desktop} {
        display: inline-block;
        margin-left: 2rem;
        margin-bottom: 0;
      }

      &:hover {
        opacity: 0.75;
      }
    }

    button {
      border-radius: $inputRadius;
      border: 0;
      text-transform: uppercase;
      font-weight: 600;
      padding: 0.5rem 3rem;
      cursor: pointer;
      box-sizing: border-box;

      @media #{$desktop} {
        margin-left: 2rem;
      }

      &:disabled {
        cursor: inherit;
      }
    }
  }
}
