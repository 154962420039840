.dashboard-panel {
  .panel-row--no-plan {
    display: block;
    text-align: center;

    button {
      margin-top: 2rem;
    }

    @media #{$tablet} {
      display: flex;
      justify-content: center;

      button {
        margin-top: 0;
        margin-left: 2rem;
      }
    }

    & > span {
      color: #9e9e9e;
      font-weight: 600;
      font-size: 1.5rem;
      display: block;
    }
  }

  .panel-row--action {
    justify-content: space-between;

    & > span {
      color: #9e9e9e;
      font-weight: 600;
      font-size: 1.5rem;
    }

    button {
      margin-left: 2rem;
    }
  }

  .panel-row--card {
    display: block;

    @media #{$tablet} {
      display: flex;
    }

    .brief-card-info {
      flex-grow: 1;
    }
  }
  .panel-row--card-and-plan {
    display: block;

    @media #{$tablet} {
      display: flex;
    }

    .brief-card-info {
      margin-top: 2rem;

      @media #{$tablet} {
        margin-top: 0;
        margin-left: 2rem;
        margin-right: 2rem;
      }
    }
  }
}

.active-card-panel {
  .active-plan {
    flex-grow: 1;

    .monitoring {
      font-weight: 500;
      font-size: 1.5rem;
      display: block;
    }
    .next-payment {
      color: #8a8a8a;
      font-size: 1.3rem;
      display: block;
    }

    .btn {
      margin-top: 0.5rem;
    }
  }

  .card-controls {
    display: flex;
    margin-top: 2rem;

    @media #{$tablet} {
      margin: 0;
    }

    & > *:not(:first-child) {
      margin-left: 1rem;
    }
  }
}
