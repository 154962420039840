.form-section {
  margin-top: 3rem;
  margin-bottom: 3rem;

  &__header {
    margin-bottom: 1rem;

    .title {
      text-transform: uppercase;
      font-size: 1.5rem;
      letter-spacing: 0.3rem;
      font-weight: 400;
    }

    .error {
      margin-left: 1.5rem;
      background: #e95858;
      color: white;
      font-size: 1.2rem;
      border-radius: $inputRadius;
      padding: 0.4rem 1rem 0.4rem 2.5rem;
      background-image: url('../../img/inforow/error.svg');
      background-repeat: no-repeat;
      background-position: top 50% left 0.6rem;
      background-size: 1.4rem 1.4rem;
    }
  }
}

button.form-add-btn {
  font-size: 1.3rem;
  font-weight: 600;
  color: #737373;
  border: 0.1rem solid #737373;
  border-radius: $inputRadius;
  padding: 0.5rem 1rem;
  background: transparent;
  outline: 0;
  cursor: pointer;

  &:hover {
    background: #ebebeb;
  }

  &:focus {
    box-shadow: 0 0 0 0.3rem #cecece;
  }
}

.dynamic-row {
  margin-bottom: 1.3rem;
  display: flex;

  @media #{$tablet} {
    .text-input-wrapper {
      width: 100%;
    }
  }

  button.dynamic-delete-btn {
    width: 4rem;
    font-size: 2rem;
    border: 0;
    background: transparent;
    cursor: pointer;
    border-radius: $inputRadius;
    transition: all 200ms ease;
    outline: 0;

    @media #{$tablet} {
      height: 5.3rem;
    }

    &:hover {
      background: #ebebeb;
    }

    &:focus {
      box-shadow: inset 0 0 0 0.3rem #cecece;
    }
  }

  &__content {
    flex-grow: 1;

    @media #{$tablet} {
      display: flex;
      align-items: flex-start;
      width: 100%;
    }

    @media #{$tablet} {
      .text-input-wrapper:not(:last-child) {
        margin-right: 1rem;
      }
    }
  }

  &__controls {
    margin-top: 2.4rem;
    margin-bottom: 1rem;
    margin-left: 1rem;
    display: flex;
    align-items: stretch;

    @media #{$tablet} {
    }
  }
}

.control-row {
  width: 100%;

  @media #{$tablet} {
    display: flex;
    align-items: flex-start;
    margin-bottom: 1.3rem;
  }

  .btn {
    width: 100%;
    margin-top: 1.5rem;

    @media #{$tablet} {
      width: auto;
      margin-top: 2.4rem;
      padding-top: 1.6rem;
      padding-bottom: 1.6rem;
    }

    .arrow {
      @media #{$tablet} {
        top: 1.9rem !important;
      }
    }
  }

  @media #{$tablet} {
    .text-input-wrapper:not(:last-child) {
      margin-right: 1rem;
    }
  }
}

.submit-row {
  margin-top: 3rem;

  .btn {
    width: 100%;

    &:not(:first-child) {
      margin-left: 2rem;
    }

    @media #{$tablet} {
      width: auto;
    }
  }
}

.password-checkup {
  background-color: #fbfbfb;
  border: 0.1rem solid #d3d3d3;
  padding: 2rem 3rem;
  font-size: 1.3rem;
  margin-bottom: 2rem;

  span {
    display: block;
    background-repeat: no-repeat;
    background-position: left 0 top 0.3rem;
    background-image: url('../../img/icons/invalid.svg');
    padding-left: 2rem;
    line-height: 1.3;

    &:not(:last-child) {
      margin-bottom: 0.3rem;
    }

    &.valid {
      background-image: url('../../img/icons/valid.svg');
    }
  }
}
