.account-settings {
  @media #{$desktop} {
    display: flex;
    align-items: stretch;
  }

  &__item {
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    padding: 3rem 0;

    &:first-child {
      padding-top: 0;
    }
    &:last-child {
      padding-bottom: 0;
    }

    &:not(:last-child) {
      border-bottom: 0.1rem solid #c6c6c6;
    }

    @media #{$desktop} {
      margin: 0;
      padding: 0;

      &:not(:last-child) {
        margin-right: 4rem;
        border-bottom: 0;
      }

      &:not(:first-child) {
        border-left: 0.1rem solid #c6c6c6;
        padding-left: 4rem;
      }
    }

    &__title {
      font-size: 1.3rem;
      text-transform: uppercase;
      font-weight: 400;
      letter-spacing: 0.2rem;
      margin: 0 0 1rem 0;
      font-weight: 500;
    }

    &__info {
      flex-grow: 1;

      span {
        font-size: 1.3rem;
        display: block;
      }
    }

    &__btn {
      margin-top: 4rem;
    }
  }
}
