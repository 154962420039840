.register-form {
  .text-input-wrapper {
    /* First row */
    &.firstName {
      @media #{$tablet} {
        width: 33%;
      }
    }
    &.middleName {
      @media #{$tablet} {
        width: 33%;
      }
    }
    &.lastName {
      @media #{$tablet} {
        width: 33%;
      }
    }

    /* Second row */
    &.email {
      @media #{$tablet} {
        width: 60%;
      }
    }
    &.birthday {
      @media #{$tablet} {
        width: 40%;
      }
    }

    /* Last row */
    &.street {
      @media #{$tablet} {
        width: 33%;
      }
    }
    &.city {
      @media #{$tablet} {
        width: 33%;
      }
    }
    &.zip {
      @media #{$tablet} {
        width: 33%;
      }
    }
  }

  .submit-row {
    text-align: center;
  }
}
