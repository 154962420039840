.control-row.search-params {
  margin-bottom: 3rem;

  .text-input-wrapper {
    &.to,
    &.from {
      width: auto;

      @media #{$tablet} {
        width: 20rem;
        flex-grow: 0;
      }
    }
  }

  .btn {
    @media #{$tablet} {
      flex-grow: 0;
    }
  }
}
