#scan-page {
  #scan-loader {
    margin: 0 auto;
    width: 10rem;
    margin-bottom: 3rem;
  }

  .scan-results-controls {
    margin-top: 2rem;
    .btn {
      width: 100%;
    }

    @media #{$tablet} {
      margin-top: -3rem;
      display: flex;
      justify-content: flex-end;

      .btn {
        width: auto;
      }
    }
  }
}

.scan-form {
  max-width: 70rem;
  margin: 0 auto;
}
