.not-found-page {
  h1 {
    font-size: 2.5rem;
  }

  img#not-found-illustration {
    max-width: 80%;
    width: 50rem;
  }
}
