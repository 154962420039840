.panel-row--checkout {
  width: 100%;

  @media #{$tablet} {
    display: flex;
    align-items: center;
  }

  .brief-card-info {
    flex-grow: 1;
    margin-bottom: 2rem;

    @media #{$tablet} {
      margin-bottom: 0;
    }
  }

  .checkout-options {
    button {
      width: 100%;

      @media #{$tablet} {
        width: auto;
      }
    }

    & > * {
      &:not(:last-child) {
        margin-bottom: 1rem;
      }

      @media #{$tablet} {
        &:not(:last-child) {
          margin-bottom: 0;
        }
      }
    }

    @media #{$tablet} {
      display: flex;

      button {
        margin-left: 1rem;
      }
    }
  }
}
