.react-datepicker-wrapper {
  display: block;
}

.react-datepicker {
  font-family: inherit;
  border-radius: $inputRadius;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border: 1px solid #aeaeae;
}

/* Navigation arrows */
.react-datepicker__navigation {
  background-size: contain;
  width: 1.5rem;
  height: 1.5rem;
  background-repeat: no-repeat;
  background-position: center;
  border: 0;
  opacity: 0.5;
  transition: opacity 200ms ease;

  &:hover {
    opacity: 1;
  }
}
.react-datepicker__navigation--previous {
  background-image: url('../../img/datepicker/arrow-left.svg');
}
.react-datepicker__navigation--next {
  background-image: url('../../img/datepicker/arrow-right.svg');
}

/* Triangle */
.react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle {
  border-bottom-color: white;
}
.react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle {
  top: 1px;
}

.react-datepicker__header {
  background: transparent;
  border-bottom-color: #aeaeae;

  .react-datepicker__current-month {
    font-size: 1.3rem;
    margin-bottom: 0.3rem;
  }
  .react-datepicker__day-name {
    font-size: 1.1rem;
    width: 2.5rem;
    height: 2.5rem;
    line-height: 2.5;
    text-transform: uppercase;
    color: #8c8c8c;
  }
}

/* Month element */
.react-datepicker__month {
  /* Day box */
  .react-datepicker__day {
    font-size: 1.2rem;
    border-radius: 0;
    width: 2.5rem;
    height: 2.5rem;
    line-height: 2;
    outline: 0;
  }

  /* Today's Date */
  .react-datepicker__day--today {
    font-weight: inherit;
  }

  /* Selected Date */
  .react-datepicker__day--selected {
    background-color: $brightBlue;
  }
}
