.simple-layout {
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  header.simple-layout__header {
    padding: 2rem 0;

    .container {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .header-questions {
        &__question {
          color: #737373;
          margin-right: 2rem;
          display: none;

          @media #{$landscapePhone} {
            display: inline-block;
          }
        }
      }
    }
  }

  main.simple-layout__main {
    .container {
      max-width: 110rem;
    }
    flex-grow: 1;
  }

  footer.simple-layout__footer {
    margin: 8rem 0 3rem;
    text-align: center;

    p {
      font-size: 1.3rem;
      margin: 0;

      a {
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

.btn--chat {
  border: 0.1rem solid #737373;
  padding: 1.5rem 2.5rem;
  background: transparent;
  border-radius: $inputRadius;
  cursor: pointer;
  background-image: url('../../../img/icons/chat-dark.svg');
  background-repeat: no-repeat;
  background-position: left 1.5rem top 50%;
  padding-left: 5rem;
  transition: all 150ms ease;
  outline: 0;

  span {
    font-size: 1.4rem;
    font-weight: 600;
    color: #737373;
  }

  &:hover {
    background-image: url('../../../img/icons/chat-white.svg');
    background-color: #737373;

    span {
      color: #fff;
    }
  }

  &:focus {
    box-shadow: 0 0 0 0.3rem #1375a7;
  }
}
