.dashboard-layout__sidebar {
  position: fixed;
  width: 100%;
  z-index: 4;
  flex-shrink: 0;

  @media #{$tablet} {
    min-width: 25rem;
    width: auto;
    position: static;
    background-color: #0e2f4b;
  }

  &__logo {
    padding: 2rem;
    display: flex;
    background-color: #0e2f4b;
    width: 15rem;
    height: 6.6rem;
    box-sizing: border-box;

    @media #{$tablet} {
      width: auto;
      height: auto;
    }

    .logo-symbol {
      display: block;
      width: 5rem;

      @media #{$tablet} {
        display: none;
      }
    }

    .logo-portal {
      display: none;

      @media #{$tablet} {
        width: 15rem;
        display: block;
      }
    }

    .hamburger {
      background-image: url('../../../img/icons/hamburger.svg');
      background-position: center;
      background-repeat: no-repeat;
      border: 0;
      background-color: transparent;
      outline: 0;
      width: 5rem;
      cursor: pointer;
      margin-left: 1rem;

      &.open {
        background-image: url('../../../img/icons/hamburger-close.svg');
      }
    }

    @media #{$tablet} {
      padding: 3rem;

      .hamburger {
        display: none;
      }
    }
  }

  &__nav {
    $sidebarPadding: 3rem;
    display: none;
    background-color: #0a243b;
    width: 100%;

    @media #{$tablet} {
      display: block;
      position: static;
    }
    &--open {
      display: block;
    }

    ul {
      list-style: none;
      padding: 0;
      margin: 0;

      li {
        border-top: 0.1rem solid #185370;

        &:last-child {
          border-bottom: 0.1rem solid #185370;
        }

        a {
          display: block;
          color: #84a8ba;
          padding-left: $sidebarPadding;
          padding-right: $sidebarPadding;
          font-size: 1.4rem;
          padding-top: 2rem;
          padding-bottom: 2rem;
          text-decoration: none;
          transition: background 100ms ease;
          display: flex;
          align-items: center;

          span,
          span::before {
            color: #84a8ba;
          }

          span.icon {
            margin-right: 1rem;
          }

          &:hover {
            background-color: #0e2f4b;
          }

          &.active {
            background-color: #0e385c;

            span,
            span::before {
              color: white;
            }
          }
        }

        ul {
          li {
            a {
              padding-top: 1.2rem;
              padding-bottom: 1.2rem;
              padding-left: 6rem;
              padding-right: 6rem;

              .icon {
                font-size: 1rem;
              }
            }
          }
        }
      }
    }
  }
}
