.message-layout {
  text-align: center;
  width: auto;

  @media #{$tablet} {
    width: 50rem;
  }

  p {
    margin-bottom: 1rem;
    &.total {
      font-size: 3rem;
      font-weight: 500;
      color: black;
    }
  }

  &__controls {
    display: flex;
    justify-content: center;
    margin-top: 4rem;
    margin-bottom: 1rem;

    .btn:not(:last-child) {
      margin-right: 2rem;
    }
  }
}
