.autocomplete-dropdown-container {
  position: absolute;
  width: 100%;
  z-index: 5;
  overflow: hidden;
  border-radius: $inputRadius;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);

  .suggestion-item {
    cursor: pointer;
    padding: 0.6rem 1.5rem;
    box-sizing: border-box;
    width: 100%;
    background: white;

    span {
      font-size: 1.3rem;
      text-transform: uppercase;
    }

    &--active {
      background: #f6f6f6;
    }
  }

  .suggestion-item:not(:last-child) {
    border-bottom: 0.1rem solid #dddddd;
  }
}
