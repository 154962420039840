.checkbox-input-wrapper {
  flex-grow: 1;

  label {
    display: inline-flex;
    cursor: pointer;

    input[type='checkbox'] {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;

      &:checked ~ .checkmark-box {
        background-color: #2196f3;
        border: 0.1rem solid #2196f3;
      }

      &:checked ~ .checkmark-box {
        .checkmark {
          display: block;
        }
      }

      &:checked:focus ~ .checkmark-box {
        box-shadow: 0 0 0 0.2rem #296b8b;
      }

      &:focus ~ .checkmark-box {
        box-shadow: 0 0 0 0.2rem #7a7a7a;
      }
    }

    span.label {
      color: #808080;
      font-size: 1.4rem;
      flex-grow: 1;
      margin-left: 1.5rem;

      a {
        color: inherit;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }

      &.error {
        color: $errorRed;
      }
    }

    .checkmark-box {
      display: inline-block;
      height: 1.8rem;
      width: 1.8rem;
      background: transparent;
      border: 0.1rem solid $inputBorderColor;
      border-radius: $inputRadius;
      transition: all 150ms ease;
      flex-shrink: 0;

      .checkmark {
        display: none;
        position: absolute;
        font-size: 1.2rem;
        margin-left: 0.3rem;
        margin-top: 0.3rem;
        color: #ffffff;
      }
    }

    &:hover .checkmark-box {
      background-color: #e6e6e6;
    }
  }
}
