.popup-shade {
  background-color: rgba(0, 0, 0, 0.25);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  transition: all 200ms ease;
}

.popup-wrapper {
  &-enter {
    .popup {
      opacity: 0;
      margin-top: 3rem;
    }
    .popup-shade {
      opacity: 0;
    }
  }
  &-enter-active {
    .popup {
      opacity: 1;
      margin-top: 0;
    }
    .popup-shade {
      opacity: 1;
    }
  }
  &-exit {
    .popup {
      opacity: 1;
      margin-top: 0;
    }
    .popup-shade {
      opacity: 1;
    }
  }
  &-exit-active {
    .popup {
      opacity: 0;
      margin-top: 3rem;
    }
    .popup-shade {
      opacity: 0;
    }
  }
}

.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: 100vh;
  max-width: 100vw;
  overflow-y: auto;
  box-sizing: border-box;
  border-radius: $inputRadius;
  padding: 2rem;
  background: #ffffff;
  z-index: 100;
  transition: all 200ms ease;
  width: 100%;

  padding: 5rem 2rem;

  @media #{$landscapePhone} {
    padding: 2rem;
    min-width: 50rem;
    width: auto;
  }

  .submit-row {
    display: flex;
    justify-content: flex-end;
  }

  &__header {
    display: flex;
    align-items: center;
    border-bottom: 0.1rem solid silver;
    padding-bottom: 1rem;
    margin-bottom: 2rem;

    &__title {
      flex-grow: 1;
      font-weight: 400;
      font-size: 1.8rem;
      margin: 0;
    }

    &__close {
      color: #a7a7a7;
      font-size: 1.2rem;
      cursor: pointer;
      padding: 1rem 0.5rem;
      border-radius: $inputRadius;
      outline: 0;
      background: transparent;
      border: 0;
      transition: all 150ms ease;

      &:hover {
        color: #494949;
        background-color: #f0f0f0;
      }

      &:focus {
        box-shadow: 0 0 0 0.3rem #cecece;
      }
    }
  }
}
