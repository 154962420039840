.profile-status {
  display: flex;

  &__icon {
    img {
      display: block;
      width: 6rem;

      &.in-progress {
        animation: rotate 4s linear infinite;
      }

      @keyframes rotate {
        to {
          transform: rotate(360deg);
        }
      }
    }
  }

  &__info {
    margin-left: 2rem;

    &__label {
      color: #8a8a8a;
      font-size: 1.3rem;
      text-transform: uppercase;
      display: block;
    }

    & > h1 {
      font-size: 2.6rem;
      color: #000000;
      font-weight: 400;
      margin: 0;
      padding: 0;
      display: block;
    }
  }
}

.records {
  margin-top: 4rem;

  &__header {
    border-bottom: 0.2rem solid #d5dbe7;
    margin-bottom: 4rem;
    display: flex;

    button {
      display: inline-block;
      background: transparent;
      border: 0;
      outline: 0;
      cursor: pointer;
      padding: 1rem 1.5rem;
      transition: all 100ms ease;
      color: #757f93;

      @media #{$tablet} {
        padding: 1rem 4rem;
      }

      &:hover {
        background: #f0f0f0;
      }

      &.new {
        &:hover,
        &.active {
          color: #3bbcfb;
          box-shadow: 0 0.2rem 0 0 #3bbcfb;
        }
      }
      &.inProgress {
        &:hover,
        &.active {
          color: #fc0017;
          box-shadow: 0 0.2rem 0 0 #fc0017;
        }
      }
      &.removed {
        &:hover,
        &.active {
          color: #38c387;
          box-shadow: 0 0.2rem 0 0 #38c387;
        }
      }

      .number {
        font-size: 3rem;
        margin-bottom: -0.7rem;

        @media #{$landscapePhone} {
          font-size: 4rem;
        }
      }
      .label {
        font-size: 1.2rem;
        text-transform: uppercase;

        @media #{$landscapePhone} {
          letter-spacing: 0.3rem;
        }
      }
    }
  }
}
