.plans-wrapper {
  @media #{$tablet} {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
  }

  .plan {
    box-shadow: $flatBoxShaddow;
    color: #000000;
    border-radius: 0.7rem;
    padding: 3rem 2rem 2rem 2rem;
    text-align: center;
    box-sizing: border-box;
    border: 0.2rem solid white;
    transition: all 150ms ease;
    cursor: pointer;

    display: flex;
    align-items: center;

    @media #{$tablet} {
      width: 20%;
      max-width: 20rem;
      display: block;
    }

    &:not(:last-child) {
      margin-bottom: 2rem;

      @media #{$tablet} {
        margin-bottom: 0;
        margin-right: 2rem;
      }
    }

    &:hover {
      border: 0.2rem solid #43bdf9;
    }

    &__icon {
      height: 4.5rem;
      margin-right: 2rem;
      width: 4.5rem;

      @media #{$tablet} {
        width: auto;
        height: 6.5rem;
        margin-right: 0;
        margin-bottom: 2rem;
      }
    }
    &__title {
      font-size: 1.5rem;
      color: #000000;
      font-weight: 400;
      margin: 0 2rem 0.5rem 0;

      @media #{$tablet} {
        margin: 0 0 0.5rem 0;
      }
    }
    &__discount {
      text-transform: uppercase;
      background-color: #ffeece;
      color: #654d15;
      display: inline-block;
      font-weight: 600;
      border-radius: $inputRadius;
      padding: 0.2rem 0.8rem;
      font-size: 1.3rem;
    }
  }
}
