/* Icomoon */
@font-face {
  font-family: 'icomoon';
  src: url('fonts/icomoon.eot');
  src: url('fonts/icomoon.eot?#iefix') format('embedded-opentype'), url('fonts/icomoon.woff') format('woff'),
    url('fonts/icomoon.ttf') format('truetype'), url('fonts/icomoon.svg#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-attention:before {
  content: '\e90a';
  color: #fff;
}
.icon-purchase:before {
  content: '\e90b';
  color: #fff;
}
.icon-card:before {
  content: '\e909';
  color: #c0c0c0;
}
.icon-checkmark:before {
  content: '\e904';
}
.icon-trash:before {
  content: '\e905';
  color: #a7a7a7;
}
.icon-profile:before {
  content: '\e900';
  color: #84a8ba;
}
.icon-profile-scanner:before {
  content: '\e901';
  color: #84a8ba;
}
.icon-resource:before {
  content: '\e902';
  color: #fff;
}
.icon-save:before {
  content: '\e907';
  color: #fff;
}
.icon-plus:before {
  content: '\e908';
  color: #fff;
}
.icon-arrow-right:before {
  content: '\e906';
}
.icon-arrow_forward_ios:before {
  content: '\e903';
}
