.login-layout {
  display: flex;
  align-items: stretch;
  min-height: 100vh;

  &__sidebar {
    text-align: left;
    display: flex;
    flex-direction: column;
    padding: 3rem;
    width: 100%;

    @media #{$tablet} {
      padding: 6rem 6rem;
      width: 30rem;
    }

    @media #{$desktop} {
      padding: 7rem 11rem;
    }

    .logo {
      display: block;
      width: 6rem;
      margin-bottom: 2rem;
    }

    &__content {
      margin-bottom: 6rem;
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .title {
        display: block;
        width: 100%;
        margin-bottom: 3rem;
      }

      .description {
        display: block;
        width: 100%;
        margin-bottom: 2.5rem;
        font-size: 1.4srem;
      }

      .after-text {
        margin-top: 3rem;
        margin-bottom: 0;
        width: 100%;
        font-size: 1.3rem;
        text-align: left;
      }

      .form {
        .submit-row .btn {
          width: 100%;
        }
      }
    }
  }

  &__tips {
    flex-grow: 1;
    position: relative;
    overflow: hidden;
    display: none;

    @media #{$tablet} {
      display: block;
    }

    .login-tip-background {
      z-index: -1;
      background-size: cover;
      background-position: center center;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      animation-duration: 4s;
      animation-timing-function: ease-out;
      animation-delay: 0.5s;
      animation-iteration-count: 1;
      animation-name: zoomIn;
      animation-fill-mode: forwards;
    }

    @keyframes zoomIn {
      0% {
        transform: scale(1.3);
      }
      100% {
        transform: scale(1);
      }
    }

    .login-tips-curtain {
      z-index: 1;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: $bodyBackgroundColor;
      animation-duration: 1.5s;
      animation-timing-function: ease-in-out;
      animation-delay: 0.5s;
      animation-iteration-count: 1;
      animation-name: tipCurtainReveal;
      animation-fill-mode: forwards;
    }

    @keyframes tipCurtainReveal {
      0% {
        left: 0;
        width: 100%;
      }
      100% {
        left: 100%;
        width: 0%;
      }
    }

    .login-tip {
      z-index: 0;
      text-align: center;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      max-width: 50rem;
      padding: 0 3rem;
      box-sizing: border-box;

      h2 {
        color: white;
        font-weight: 700;
        font-size: 2.4rem;
        margin: 0 0 2rem 0;
        animation-delay: 1.2s;
        opacity: 0;
      }

      p {
        animation-delay: 2s;
        color: white;
        font-size: 1.6rem;
        margin: 0;
        opacity: 0;
      }

      h2,
      p {
        animation-duration: 1s;
        animation-timing-function: ease-in-out;
        animation-iteration-count: 1;
        animation-name: textFadeIn;
        animation-fill-mode: forwards;
      }

      @keyframes textFadeIn {
        0% {
          opacity: 0;
        }
        100% {
          opacity: 1;
        }
      }
    }
  }
}
