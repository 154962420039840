.smooth-animate {
  &.visible {
    opacity: 1;
    transition: all 1000ms ease;
    transition-delay: 800ms;
  }
  &.hidden {
    opacity: 0;
    transition: all 500ms ease;
  }
}
